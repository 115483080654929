@import '../../Styles/settings';

.root {
  padding-top: $mobile-header-height;
}

.rootPaddingBottom {
  padding-bottom: $verticleBlockPaddingXS;
}

.contentAreaWrapperPaddingTop {
  padding-top: $verticleBlockPaddingXS;
}

.topContainer{
  margin-bottom: 64px;
}

@media #{$sm-up} {
  .root {
    padding-top: 75px;
    max-width: none;
  }
}

@media #{$md-up} {
  .rootPaddingBottom {
    padding-bottom: $verticleBlockPaddingMD;
  }

  .topContainer{
    margin-bottom: 200px;
  }

  .contentAreaWrapperPaddingTop {
    padding-top: $verticleBlockPaddingMD;
  }
}
