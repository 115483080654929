@import '../../../Styles/settings';

@keyframes svgAnimationInFlipped {
  0% {
    transform: translateX(0) scaleX(-1);
  }
  100% {
    transform: translateX(6px) scaleX(-1);
  }
}

@keyframes svgAnimationOutFlipped {
  0% {
    transform: translateX(6px) scaleX(-1);
  }
  100% {
    transform: translateX(0) scaleX(-1);
  }
}

.btnContainer {
  position: absolute;
  margin-bottom: 24px;
  bottom: 0;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.btnContent {
  @include HelveticaMedium;
  font-size: 12px;
  line-height: 16px;
}

@media #{$md-up} {
  .btnContent {
    font-size: 18px;
    line-height: 24px;
  }
  .root {
    > img {
      opacity: 1;
      transition: opacity 0.2s linear;
      animation: none;
    }

    button > svg {
      animation: svgAnimationOutFlipped $main-timing-function 0.3s !important;
    }

    &:hover img {
      opacity: 0.8 !important;
      cursor: pointer;
    }
    &:hover button > svg {
      animation: svgAnimationInFlipped $main-timing-function 0.3s !important;
      animation-fill-mode: forwards !important;
    }
    &:hover button {
      border: 1px solid $midnight-blue !important;
    }
  }
}
